$black: #222222;

@font-face {
  font-family: 'Gymnastic-Bodies';
  src: url('./Fonts/Gymnastic-Bodies.eot?vgxo48');
  src: url('./Fonts/Gymnastic-Bodies.eot?vgxo48#iefix') format('embedded-opentype'),
    url('./Fonts/Gymnastic-Bodies.ttf?vgxo48') format('truetype'),
    url('./Fonts/Gymnastic-Bodies.woff?vgxo48') format('woff'),
    url('./Fonts/Gymnastic-Bodies.svg?vgxo48#Gymnastic-Bodies') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.gb-icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: rgb(66, 66, 66);
  display: inline-block;
  font-family: 'Gymnastic-Bodies' !important;
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: inherit;
  margin-right: 4px;
  speak: none;
  text-transform: none;
  vertical-align: top;
}

.gb-avocado:before {
  content: "\e900";
}

.gb-breakfast:before {
  content: "\e901";
}

.gb-crown:before {
  content: "\e902";
}

.gb-difficulty-1 .path1:before {
  content: "\e903";
}

.gb-difficulty-1 .path2:before {
  content: "\e904";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-difficulty-1 .path3:before {
  content: "\e905";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-difficulty-1 .path4:before {
  content: "\e906";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-difficulty-2 .path1:before {
  content: "\e907";
}

.gb-difficulty-2 .path2:before {
  content: "\e908";
  margin-left: -1em;
}

.gb-difficulty-2 .path3:before {
  content: "\e909";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-difficulty-2 .path4:before {
  content: "\e90a";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-difficulty-3 .path1:before {
  content: "\e90b";
}

.gb-difficulty-3 .path2:before {
  content: "\e90c";
  margin-left: -1em;
}

.gb-difficulty-3 .path3:before {
  content: "\e90d";
  margin-left: -1em;
}

.gb-difficulty-3 .path4:before {
  content: "\e90e";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-difficulty-4:before {
  content: "\e90f";
}

.gb-drink:before {
  content: "\e910";
}

.gb-facebook:before {
  content: "\e911";
}

.gb-grain:before {
  content: "\e912";
}

.gb-gymfit-by,
.gb-gymfit {
  font-size: 1.875rem !important;
  position: relative;
  top: 3px;
}

.gb-gymfit-by:before {
  content: "\e913";
}

.gb-gymfit:before {
  content: "\e914";
}

.gb-gymnasticbodies-logo:before {
  content: "\e915";
}

.gb-instagram:before {
  content: "\e916";
}

.gb-intensity-1 .path1:before {
  content: "\e917";
}

.gb-intensity-1 .path2:before {
  content: "\e918";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-intensity-1 .path3:before {
  content: "\e919";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-intensity-1 .path4:before {
  content: "\e91a";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-intensity-2 .path1:before {
  content: "\e91b";
  color: rgb(0, 0, 0);
}

.gb-intensity-2 .path2:before {
  content: "\e91c";
  margin-left: -1em;
}

.gb-intensity-2 .path3:before {
  content: "\e91d";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-intensity-2 .path4:before {
  content: "\e91e";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-intensity-3 .path1:before {
  content: "\e91f";
}

.gb-intensity-3 .path2:before {
  content: "\e920";
  margin-left: -1em;
}

.gb-intensity-3 .path3:before {
  content: "\e921";
  margin-left: -1em;
}

.gb-intensity-3 .path4:before {
  content: "\e922";
  margin-left: -1em;
  opacity: 0.3;
}

.gb-intensity-4:before {
  content: "\e923";
}

.gb-mark{
  border-right: 1px solid $black;
  font-size: 1.3415em;
  margin-right: 8px;
  padding: 4px 8px 4px 0;
}

.gb-mark:before {
  content: "\e924";
}

.gb-steak:before {
  content: "\e925";
}

.gb-twitter:before {
  content: "\e926";
}

.gb-vegetables:before {
  content: "\e927";
}

.gb-white:before {
  content: "\e92b";
  color: white;
}

.gb-mobility:before {
  content: "\e92e";
  color: #107ece;
}

.gb-litetime:before {
  content: "\e92f";
  color: #32c606;
}

.gb-thrive:before {
  content: "\e930";
}

.gb-equipment:before {
  content: "\e931";
  font-size: 72px;
  line-height: .4;
  color: #fff400;
}

.gb-pref:before{
  content: "\e936";
  color: white;
}

.gb-history:before{
  content: "\e939";
  color: white;
}

.gb-history-reg:before{
  content: "\e939";
}

.gb-curl:before{
  content: "\e937";
  color: white;
  width: 24px;
  text-align: center;
}

.gb-workout:before{
  content: "\e938";
  color: white;
}

.header-logo-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 5px 0;
}

.header-hero-link {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  text-decoration: none;
  padding: 5px 0;
  .gb-icon{
    color: white;
    &.gb-mark{
      border-right: 1px solid white;
    }
  }
}

.header-hero-link-free{
  display: flex;
  align-items: flex-start;
  justify-content: left;
  text-decoration: none;
  padding: 5px 0;
  align-items: center;
  .gb-icon{
    color: white;
    &.gb-white{
      border-right: 1px solid white ;
      -webkit-transform: skewX(-10deg);
      -moz-transform: skewX(-10deg);
      -ms-transform: skewX(-10deg);
      transform: skewX(-10deg);;
      padding-right: 4px;
    }
  }
}

.gf-footer{
  color: rgba(34,34,34,0.5) !important;
}
.gf-footer-border{
  border-right: 1px solid rgba(34,34,34,0.5);
}
