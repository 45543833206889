.gf-nuts-and-bolts{
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1.25px solid black;
    margin: 0 8px;
    position: relative;
    bottom: -24px;
    height: fit-content;
    @media only screen and (max-device-width : 375px) {
      bottom: -18px;
    }
  }
  span{
    &::after{
      content: 'THINGS YOU NEED TO KNOW';
      background: -webkit-linear-gradient(-45deg, #FC4200 43%, #FFA61C 60%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
