.gf-schedule-root {
  border-radius: 4px;
  border: 1px solid #dddddd;
  display: flex;
  height: 105px;
  list-style: none;
  margin: 0;
  padding: 0;

  &>li:first-child {
    border-left: none;
  }

  .gf-day-container {
    width: calc(100%/7);
    border-left: 1px solid #dddddd;
    cursor: pointer;

    .gf-day-text {
      background: #F5F5F5;
      border-bottom: 1px solid #dddddd;
      font-size: 12px;
      font-weight: 600;
      line-height: 36px;
      margin-bottom: 8px;
      margin-top: 0;
      padding: 0 8px;
      text-align: center;
      text-transform: uppercase;
    }

    .gf-date-section {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      text-align: center;

      .gf-date {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04em;
        margin: 4px 0 8px 0;
        padding: 0 8px
      }
      .gf-classes{
        display: flex;
        flex-direction: row;
        justify-content: center;
        span{
          color: #9e9e9e;
          font-family: sans-serif;
          font-size: 1.25rem;
        }
      }
    }
  }
}
