.modal {
  background: white;
  border-bottom-left-radius: 0;
  border-radius: 16px;
  border-top-left-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // min-height: 75%;
  outline: none;
  padding: 24px;
  transition: 0.25s all ease;
  width: 85%;
  will-change: transform;
  z-index: 15;
  max-height: 80vh;
  overflow: scroll;
  max-width: 1200px;
  @media only screen and (max-width: 1200px) and (min-width: 769px) {
    width: 95%;
  }
  @media (max-width: 425px) {
    padding: 16px;
  }
}

.thive-drawer{
  padding: 0 0 0 72px;
}

.open-padding {
  padding-left: 210px;
  width: 95%;
  @media (max-width: 1024px) {
    padding-left: 60px;
  }
}

.closed-padding {
  padding-left: 65px;
  width: 85% ;
  @media only screen and (max-width: 1200px) and (min-width: 769px) {
    width: 90%;
  }
  @media (max-width: 426px) {
    padding-left: 56px;
  }
}

@media (max-width: 768px) {
  .modal {
    width: 100%;
    max-width: 90%;
    margin-bottom: 0;
  }
}

@media (max-width: 425px) {
  .modal {
    width: 100%;
    max-width: 98%;
    margin-bottom: 0;
    max-height: 75vh;
    overflow-x: scroll;
  }
}

.container-div{
  justify-content: start !important;
  @media (max-width: 425px) {
    top: 50px !important;
  }
}

.close-button {
  position: absolute !important;
  right: 8px;
  top: 8px;
}

.max-width{
  max-width: 75%;
}

.history-drawer{

  &.closed-padding {
    padding-left: 65px;
    @media only screen and (max-width: 1200px) and (min-width: 769px) {
      width: 90%;
    }
    @media (max-width: 426px) {
      padding: 12px;
      padding-left: 65px;
    }
  }
}

